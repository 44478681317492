import fetch from 'cross-fetch'
import apiRequest from './apiRequest'

export const getUserDealers = async (token, dealerId, params) => {
  const { UserEmailAddress } = params || {}

  return await apiRequest({ url: `https://api.greasecrm.com/user/dealers/${UserEmailAddress}`, token })

}

export const getDealerUsers = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealer/users/${dealerId}`, token })
}

export const addDealerUser = async (token, dealerId, params) => {
  const { body } = params || {}
  body.DealerId = dealerId

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/add-user`,
    method: 'POST',
    token,
    body
  })
}

export const getUserMetadata = async (token, dealerId, params) => {
  const { user_id } = params || {}

  return await apiRequest({ url: `https://dev-49m7ydqc.us.auth0.com/api/v2/users/${user_id}`, token })

}
