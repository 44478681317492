import fetch from 'cross-fetch'
import apiRequest from './apiRequest'

export const getDealerLabels = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealers/labels/${dealerId}`, token })
}

export const getDealerStock = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealers/stock/${dealerId}`, token })
}

export const addDealerLabel = async (token, dealerId, params) => {
  const { body } = params || {}

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/labels/${dealerId}`,
    method: 'POST',
    token,
    body
  })
}

export const getDealerById = async (id, token) => {

  const response = await fetch(`https://api.greasecrm.com/dealers/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })

  return await response.json()
}

export const updateDealerById = async (token, dealerId, params) => {
  const { body } = params || {}

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/${dealerId}`,
    method: 'POST',
    token,
    body
  })
}

export const addDealer = async (token, dealerId, params) => {
  const { body } = params || {}

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/add`,
    method: 'POST',
    token,
    body
  })
}

export const getDealerFacebookUser = async (id, token) => {

  const response = await fetch(`https://api.greasecrm.com/dealers-facebook-user/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })

  return await response.json()
}

export const updateDealerFacebookUser = async (id, facebook_user, token) => {
  const response = await fetch(`https://api.greasecrm.com/dealers-facebook-user/${id}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(facebook_user)
  });
  return await response.json();
}


export const getDealerWhitelist = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealer/whitelist/${dealerId}`, token })
}

export const getDealerBlacklist = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealer/blacklist/${dealerId}`, token })
}

export const addEmailToDealerWhitelist = async (token, dealerId, params) => {
  const { body } = params || {}
  body.DealerId = dealerId

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/add-to-whitelist`,
    method: 'POST',
    token,
    body
  })
}
export const addEmailToDealerBlacklist = async (token, dealerId, params) => {
  const { body } = params || {}
  body.DealerId = dealerId

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/add-to-blacklist`,
    method: 'POST',
    token,
    body
  })
}

export const getDealerFields = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealer/fields/${dealerId}`, token })
}

export const getCurrentDealer = async (token, dealerId, params) => {
  return await apiRequest({ url: `https://api.greasecrm.com/dealers/${dealerId}`, token })
}

export const addDealerField = async (token, dealerId, params) => {
  const { body } = params || {}
  body.DealerId = dealerId

  return await apiRequest({
    url: `https://api.greasecrm.com/dealers/add-field`,
    method: 'POST',
    token,
    body
  })
}
