import EasyEdit from '../../../components/InlineEditor/EasyEdit';

export default props => {
  const { name, alias, value, setLead, updatePending, allowEdit } = props

  let isBeingUpdated = false

  if (updatePending) {
    const updateKeys = Object.keys(updatePending)

    if (updateKeys.includes(name))
      isBeingUpdated = true
  }

  const dateValue = new Date(value);
  const today = new Date();

  const difference = dateValue.getTime() - today.getTime();
  const daysDifference = Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));

  return (
    <>
      <tr>
        <th scope="row" style={{ textTransform: 'capitalize' }}>
          {typeof alias !== 'undefined' ? `${alias}` : `${name}`}
        </th>
        <td>
          {allowEdit !== false && <EasyEdit
            style={{ color: !value && '#ccc' }}
            // inputStyle={{ fontSize: 12 }}
            value={value || null}
            type="text"
            onSave={value => { setLead({ [name]: { name, value } }) }}
            // onCancel={cancel}
            placeholder={`Click to edit ${value || typeof alias !== 'undefined' ? `${alias}` : `${name}`}`}
            saveButtonLabel="Save"
            cancelButtonLabel="Cancel"
          />}
          {allowEdit === false && <>
            <p>{new Date(value).toLocaleDateString()} {new Date(value).toLocaleTimeString()}</p>
            {daysDifference > 0 && <p>({daysDifference} day{daysDifference > 1 && 's'} ago)</p>}
          </>}
          {isBeingUpdated && <p>Update pending..</p>}
        </td>
      </tr>
    </>
  )
}