import { useContext } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import DealerContext from './DealerContext'

// Low level function for getting data from  
// GL digital API
const useApiGet = () => {
  const {
    getAccessTokenSilently,
  } = useAuth0();

  const { dealerId } = useContext(DealerContext);

  const get = async (apiFunction, params, entity) => {
    const { singleRecord, wholeRecord } = params || {}

    const token = await getAccessTokenSilently();
    const dealerIdToUse = params?.dealerId ? params?.dealerId : dealerId // allow overrides of dealer id here 

    const response = await apiFunction.call(this, token, dealerIdToUse, params, entity)

    const result = await response.json()

    if (response.ok) {
      if (singleRecord === true) {
        if (result.Item) {
          if (wholeRecord === true)
            return result
          return result.Item
        }
        else {
          if (wholeRecord === true)
            return result
          return result.Items[0];
        }
      } else {
        if (wholeRecord === true)
          return result
        return result.Items;
      }
    } else {
      console.error(response)
      throw new Error(response)
    }
  }

  return get
}

export default useApiGet