import fetch from 'cross-fetch'

export default async ({ url, token, method = 'GET', body }) => {

  let params = {
    method,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  if (method === 'POST')
    params.body = JSON.stringify(body)

  const response = await fetch(url, params);

  return response;

}
