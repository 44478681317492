import React from "react";
import Logo from './logo';

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <p>
      <Logo />
    </p>
    <p>
      <a href="https://gl.digital">GL Digital</a>
    </p>
  </footer>
);

export default Footer;
