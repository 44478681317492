import DateComponent from '../../components/leads/CustomComponents/DateComponent'
const merge = require('deepmerge')

export const createView = view => (oldObj) => {
  // How to process a view on an entity in Grease: 101
  try {
    // First parse the eventbody
    const oldBody = typeof oldObj === 'string' ? JSON.parse(oldObj) : oldObj
    const oldBodyKeys = Object.keys(oldBody)

    if (typeof oldBody === 'object') {

      // Then change all fields in the event body into the format: { name: key, value: oldBody[key] }
      // And merge it with any other fields in the view
      // Unless ignore: true in the view (this means we shouldn't process that field at all)
      const newValues = oldBodyKeys.map((key, index) => {
        const oldObject = oldBody[key]

        const value = typeof oldObject.value !== 'undefined' ? oldObject.value : oldObject

        if (typeof view[key] !== 'undefined') {
          if (view[key].Ignore === 'true') {
            return { [key]: value }
          } else {
            return merge({
              name: key,
              value
            }, view[key])
          }
        } else {
          return { name: key, value: oldBody[key] }
        }

      })

      // Then add all fields that are present in the view but not in the event body to the event body
      // in the format { name: key, value: oldBody[key] } with a value of nothing 
      Object.keys(view).forEach((key) => {
        if (!oldBody.hasOwnProperty(key)) {
          newValues.push(merge({
            name: key,
            value: ''
          }, view[key]))
        }
      })


      let newObjectToReturn = {}

      newValues.forEach((item, index) => {
        if (typeof item.name !== 'undefined') {
          newObjectToReturn[item.name] = item
        } else {
          const key = Object.keys(item)[0]
          newObjectToReturn[key] = item[key]
        }
      })

      // console.log(newObjectToReturn)
      // console.log(newValues.length)
      // console.log(Object.keys(newObjectToReturn).length)
      // NOTE the lengths above should probably be the same
      return newObjectToReturn

    }
    else {
      return oldObj
    }
  }
  catch (e) {
    console.log(e)
    console.log(oldObj)
    return oldObj
  }
}

export const defaultView = createView({})

export const view = opts => createView(opts)

export const leadViewDefaults = {
  'DateUpdated': { alias: 'Date Updated', Component: DateComponent, allowEdit: false },
  'DateCreated': { alias: 'Date Created', Component: DateComponent, allowEdit: false },
  'customer_name': { alias: 'Name' },
  'customer_telephone': { alias: 'Telephone' },
  'customer_email': { alias: 'Email' },
  'vehicle_make': { alias: 'Make' },
  'vehicle_model': { alias: 'Model' },
  'vehicle_year': { alias: 'Year' },
  'vehicle_colour': { alias: 'Colour' },
  'vehicle_mileage': { alias: 'Mileage' },
  'vehicle_vrm': { alias: 'Vrm' },
  'vehicle_motstatus': { alias: 'Mot Status', allowEdit: false },
  'vehicle_motexpiry': { alias: 'Mot Expiry', allowEdit: false },
  'customer_wantedbid': { alias: 'Asking Price' },
  'customer_offer': { alias: 'Offer' },
  'customer_useragent': { hidden: true },
  'customer_leadsource': { alias: 'Lead Source' },
  'EventId': { ignore: true }
}

export const leadView = createView(leadViewDefaults)

export const actionView = createView({
  'Config': { alias: 'Config' }
})