import { useState, useContext, useEffect } from 'react'
import DealerContext from '../../hooks/DealerContext'
import { getUserDealers } from '../../utils/users'
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import useApiGet from "../../hooks/useApiGet"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"


const UserDealersList = ({ UserEmailAddress }) => {
  const [sites, setSites] = useState([])
  const [sitesLoaded, setSitesLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const getRequest = useApiGet()

  const { dealerId, dealerUrl, setCurrentDealer } = useContext(DealerContext);

  const getSites = async (e) => {
    e.preventDefault();
    if (!sitesLoaded) {
      setLoading(true)

      const result = await getRequest(getUserDealers, { UserEmailAddress })

      setSites(result)
      setLoading(false)
      setSitesLoaded(true)
    }
  }

  const handleSiteItemClick = (site) => {
    setCurrentDealer(site)
    // console.log(dealerId)
  }

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret id="profileDropDown" onClick={async e => await getSites(e)}>
        <span>{dealerUrl}</span>
      </DropdownToggle>
      <DropdownMenu>
        {loading && <span>Loading...</span>}
        {sites.map((site, index) => {
          return <DropdownItem key={index} onClick={e => handleSiteItemClick(site)}>{site.DealershipUrl}</DropdownItem>
        })}
        <DropdownItem><RouterNavLink to="/new-dealership">Add Site</RouterNavLink></DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDealersList