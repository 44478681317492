import * as React from 'react'

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216 216"
    width="2.375em"
    height="2.375em"
    style={{
      alignContent: 'center',
      alignItems: 'center'
    }}
    {...props}
  >
    <path
      fill="#212529"
      d="M108 0a108 108 0 1 0 108 108A108 108 0 0 0 108 0ZM61 191C15 168 14 54 61 25c48-29 143 30 143 83s-83 113-143 83Z"
    />
    <path
      fill="#212529"
      d="M100 152a27 27 0 0 1-15-4 26 26 0 0 1-9-10q-4-8-4-18a39 39 0 0 1 4-17 25 25 0 0 1 9-11 28 28 0 0 1 15-4 24 24 0 0 1 15 5 31 31 0 0 1 6 7l-1 12a13 13 0 0 0-4-5 20 20 0 0 0-5-3 17 17 0 0 0-6-1 17 17 0 0 0-8 2 13 13 0 0 0-6 6 21 21 0 0 0-2 9q0 8 4 13t12 5a18 18 0 0 0 9-3 14 14 0 0 0 6-6l1 12a29 29 0 0 1-10 8 23 23 0 0 1-11 3Z"
    />
    <path
      fill="#212529"
      d="M102 181a44 44 0 0 1-15-2 37 37 0 0 1-12-8l7-12a21 21 0 0 0 9 6 30 30 0 0 0 11 2 20 20 0 0 0 12-4q5-4 5-12V90h16v60q0 17-9 24t-24 7Zm44-28V63h18v90Z"
    />
  </svg >
)

export default SvgComponent
