import React, { Suspense, lazy, useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import withAuthentication from './hooks/withAuthentication'
import { getDealerFields, addDealerField } from './utils/dealers'
import useApiGet from "./hooks/useApiGet"
import DealerContext from './hooks/DealerContext'
import useCurrentDealerId from './hooks/useCurrentDealerId'

// styles
import "./App.css";

const Home = lazy(() => import('./views/Home'));
const Profile = lazy(() => import("./views/Profile"));
const Stock = lazy(() => import("./views/Stock"));
const StockSale = lazy(() => import("./views/stock/Sale"));
const Leads = lazy(() => import("./views/Leads"));
const Actions = lazy(() => import("./views/Actions"));
const ActionsAddNew = lazy(() => import("./views/actions/AddNew"));
const ActionsLibrary = lazy(() => import("./views/actions/Library"));
const Action = lazy(() => import("./views/actions/Action"));
const LeadPage = lazy(() => import("./views/leads/Lead"));
const LeadSearch = lazy(() => import("./views/leads/LeadSearch"));
const LeadsSorted = lazy(() => import("./views/leads/LeadsSorted"));
const Setup = lazy(() => import("./views/Setup"));
const AddDealership = lazy(() => import("./views/AddDealership"));


const App = () => {
  const { isLoading, error } = useAuth0();

  const { dealerId, dealerUrl, dealerFields, dealerConfig, setDealerId, setDealerUrl, setDealerFields, setDealerConfig } = useCurrentDealerId();

  const setCurrentDealer = async (dealer) => {
    setDealerId(dealer.DealerId)
    setDealerUrl(dealer.DealershipUrl)

    setDealerFields([])
    setDealerConfig({})
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <DealerContext.Provider value={{ dealerId, dealerUrl, dealerFields, dealerConfig, setCurrentDealer }}>
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/profile" component={withAuthentication(Profile)} />
                <Route path="/stock" component={withAuthentication(Stock)} exact={true} />
                <Route path="/stock/sale/" component={withAuthentication(StockSale)} exact={true} />
                <Route path="/stock/sale/:stockId" component={withAuthentication(StockSale)} exact={true} />
                <Route path="/leads" component={withAuthentication(Leads)} exact={true} />
                <Route path="/leads/search/:query" component={withAuthentication(LeadSearch)} exact={true} />
                <Route path="/leads/sorted" component={withAuthentication(LeadsSorted)} exact={true} />
                <Route path="/leads/:leadId" component={withAuthentication(LeadPage)} />
                <Route path="/actions" component={withAuthentication(Actions)} exact={true} />
                <Route path="/actions/add-new" component={withAuthentication(ActionsAddNew)} exact={true} />
                <Route path="/actions/library" component={withAuthentication(ActionsLibrary)} exact={true} />
                <Route path="/actions/edit/:actionId" component={withAuthentication(Action)} exact={true} />
                <Route path="/setup" component={withAuthentication(Setup)} />
                <Route path="/new-dealership" component={withAuthentication(AddDealership)} />
              </Switch>
            </Suspense>
          </Container>
          <Footer />
        </DealerContext.Provider>
      </div>
    </Router >
  );
};

export default App;
