import { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useApiGet from './useApiGet';
import { getUserMetadata } from '../utils/users';
import { getDealerFields, getCurrentDealer, addDealerField } from '../utils/dealers'
import { createView } from './entities/views'

const useCurrentDealerId = () => {
  const { user } = useAuth0();
  const getRequest = useApiGet()

  const [dealerId, setDealerId] = useState('')
  const [dealerUrl, setDealerUrl] = useState('')
  const [dealerFields, setDealerFields] = useState([])
  const [dealerConfig, setDealerConfig] = useState({})

  useEffect(() => {
    // .... TODO: use api to get current dealer ID?
    const getDealerId = async () => {
      if (typeof user !== 'undefined') {
        setDealerId(user["https://greasecrm.com/defaultDealerId"])
        setDealerUrl(user['https://greasecrm.com/defaultDealerUrl'])
      }
    }

    getDealerId()

  }, [user])

  useEffect(() => {
    const getLatestFields = async () => {
      const fieldsResult = await getRequest(getDealerFields, { dealerId })

      setDealerFields(() => fieldsResult.reduce((obj, item) => Object.assign(obj, { [item.Key]: { ...item } }), {}))
    }

    const getLatestConfig = async () => {
      const configResult = await getRequest(getCurrentDealer, { dealerId, singleRecord: true })

      setDealerConfig(() => configResult)
    }


    if (dealerId !== '') {
      getLatestFields()
      getLatestConfig()
    }
  }, [dealerId])

  return { dealerId, dealerUrl, dealerFields, dealerConfig, setDealerFields, setDealerUrl, setDealerId, setDealerConfig };

}

export default useCurrentDealerId
